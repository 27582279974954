<template>
  <div>
    <email-input-modal
      v-bind:is-visible.sync="showEmailInputModal"
      v-on:close="showEmailInputModal = false"
    />
    <div v-for="item in rowData" :key="item.id">
      <div class="row mt-5" :class="item.id%2 ? 'flex' : 'flex flex-row-reverse'">
        <div class="col-sm-6 col-md-6 flex flex-col justify-center">
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            class="slide-title has-poppins-bold"
          >{{item.title}}</div>
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            class="description mt-5 w-4/5"
          >{{item.descriptionOne}}</div>
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            class="description mt-5 w-4/5"
          >{{item.descriptionTwo}}</div>
        </div>

        <div data-aos="fade-up" data-aos-delay="300" class="col-sm-6 col-md-6">
          <product-card
            :title="item.imageTitle"
            :imageName="item.image"
            :imgDescription="item.imageText"
            :isVideo="item.isVideo"
            :videoLink="item.videoLink"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="flex col-6 col-md-4 col-lg-3"
          v-for="product in item.productDetails"
          :key="product.id"
        >
          <details-card
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
            :title="product.title"
            :description="product.description"
            :to="product.route"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataServies from "../../services/DataService";
import DetailsCard from "./DetailsCard.vue";
import EmailInputModal from "./EmailInputModal.vue";
import ProductCard from "./ProductCard.vue";
export default {
  components: {
    ProductCard,
    DetailsCard,
    EmailInputModal
  },
  data() {
    return {
      showEmailInputModal: false,
      rowData: DataServies.productsSectionData
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/Variables.scss";

.title {
  font-size: $font-size-52px;
}
.description {
  font-family: $font-circularStd-book;
  color: #666666;
}
</style>