<template>
  <div class="product-card mx-auto">
    <div
      class="grid grid-rows-3 grid-flow-row overflow-hidden rounded-xl shadow-xl bg-white hover:shadow-2xl transition-shadow duration-300 ease-in-out"
    >
      <div class="col-span-3 row-span-4 p-4 sm:p-7 m-1 flex">
        <div class="card-video w-full h-full" v-if="isVideo">
          <iframe
            height="277"
            width="490"
            class="object-cover"
            :src="videoLink"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            alt
          />
        </div>
        <div class="card-image w-full" v-if="!isVideo">
          <image-item
            data-aos="zoom-in-right"
            data-aos-delay="300"
            data-aos-once="true"
            class="article-item__image"
            :source="require('../../assets/images/' + imageName + '')"
          />
        </div>
      </div>

      <div class="col-span-3 row-span-1 px-4 sm:px-5 my-1">
        <div class="flex align-bottom flex-col leading-none">
          <div class="card-title w-full md:w-4/5">
            <div
              data-aos="fade-up"
              data-aos-delay="350"
              data-aos-once="true"
              class="flex items-center h-16 has-font-circular-bold"
            >{{title}}</div>
          </div>
          <div class="card-text w-4/5">
            <p
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-once="true"
              class="text-light-gray mt-2 h-24 pb-5 leading-6"
            >{{imgDescription}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageItem from "../ImageItem.vue";
export default {
  components: { ImageItem },
  data() {
    return {};
  },
  props: {
    title: String,
    imgDescription: String,
    imageName: String,
    imageText: String,
    isVideo: Boolean,
    videoLink: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/Variables.scss";
.product-card {
  // width: 22rem;

  .grid {
    padding: 1em 0.6em !important;
  }

  @media (max-width: 770px) {
    width: auto;
  }
  @media (min-width: 500px) {
    .grid {
      padding: 0.8em !important;
    }
  }

  .btn-section {
    width: 2em;
    height: 2em;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.card-title {
  font-size: 2em;
  font-weight: bold;
  // border-bottom: 1px solid #707070;
}
.card-video {
  position: relative;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.card-image {
  // height: 50px;
  // width: 60px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.card-text p {
  font-size: 1em;
  color: $text-lighter;
  font-family: $font-circularStd-book;
}
</style>