<template>
  <div class="products-page">
    <window-height>
      <div class="container flex items-center">
        <slide-one
          :slideOneTitleOne="'Our Products.'"
          :SlideOneDescription="'Experience the power of Automation, Artifical Intelligence and Machine Learning. Magure is offering products which can be easily integrated.'"
          :slideOneSideImage="'Android-amico.gif'"
        />
      </div>
    </window-height>

    <container-section>
      <products-section />
    </container-section>

    <container-section>
      <div class="trusted-customers mt-5 pt-28">
        <div data-aos="fade-up" class="slide-title font-black py-5 w-3/5">Trusted by customers</div>
        <div class="row">
          <div
            class="col-6 col-md-3 flex flex-wrap justify-center"
            v-for="(image, i) in PartnersData"
            :key="image.id"
          >
            <image-rounded
              data-aos="zoom-in-right"
              :data-aos-delay="100*i"
              :partnerName="image.image"
              :image-class="image.id == '2' || image.id == '17' ? 'p-4' : ''"
            />
          </div>
        </div>
      </div>
    </container-section>

    <container-section :containerClass="'container-fluid'" :sectionHeight="false">
      <div class="container ourServices mt-5 py-16 pt-16">
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          class="slide-title font-black"
        >Are you looking for services</div>
        <div data-aos="fade-up" data-aos-delay="350" class="slide-title font-black pb-5">like ours?</div>
        <base-button
          data-aos="fade-right"
          data-aos-delay="300"
          :href="'/contact-us'"
          id="GetInTouchoneAbout"
          class="btn-primary-dark"
          :isBorderRadiud="false"
        >Get in Touch</base-button>
      </div>
      <div class="pt-14 contact-footer relative">
        <Footer></Footer>
      </div>
    </container-section>
  </div>
</template>

<script>
import BaseButton from "../components/BaseButton.vue";
import ContainerSection from "../components/ContainerSection.vue";
import Footer from "../components/Home/Footer.vue";
import SlideOne from "../components/Home/SlideOne.vue";
import ImageRounded from "../components/ImageRounded.vue";
import ProductsSection from "../components/Products/ProductsSection";
import WindowHeight from "../components/WindowHeight.vue";
import DataServies from "../services/DataService";

export default {
  title: "Magure - Products",

  components: {
    ContainerSection,
    SlideOne,
    ProductsSection,
    BaseButton,
    ImageRounded,
    Footer,
    WindowHeight
  },
  data() {
    return {
      PartnersData: DataServies.partners
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/Pages/Products.scss";
</style>