<template>
  <div class="Products-section-two">
    <div class="row">
      <div data-aos="fade-up" data-aos-delay="300" class="col-md-3 slide-title">Products</div>
      <!--<div class="col-md-7 sm:ml-10 flex flex-col">
            <div class="slide-title">Custom ML Models</div>
            <div class="slide-title text-light-gray">Easy Data</div>
      </div>-->
    </div>
    <div class="mt-5">
      <products-row />
    </div>
  </div>
</template>

<script>
import ProductsRow from "./ProductsRow.vue";
export default {
  components: { ProductsRow }
};
</script>

<style lang="scss" scoped>
@import "../../styles/Pages/Products.scss";
</style>